import { Redirect, Router } from '@reach/router';
import { graphql } from 'gatsby';
import React from 'react';

import { DdsPreviewQuery } from '../types/graphql';
import makeLazy from '../util/make-lazy';

const Preview = makeLazy(() => import('../components/service/dds-preview'));

interface PageProps {
  data: DdsPreviewQuery;
  pageContext: {
    alternateLanguages: {
      id: string | null;
      uid: string | null;
      lang: string | null;
    }[];
    lang: string;
  };
  pageResources: {
    page: {
      path: string;
    };
  };
}

const Page: React.FC<PageProps> = ({ data, pageContext, pageResources }) =>
  typeof window !== 'undefined' ? (
    <Router primary={false} role="main" basepath={pageResources.page.path}>
      <Preview
        language={pageContext.lang}
        data={data}
        type="furnace"
        path=":model/:company/:programType"
      />
      <Preview
        language={pageContext.lang}
        data={data}
        type="circle"
        path="circle/:fileName"
      />
      <Redirect from="/" to="/" default={true} noThrow={true} />
    </Router>
  ) : null;

export default Page;

export const query = graphql`
  query DdsPreview {
    allProgrammersJson {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`;
